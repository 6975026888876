// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import SapAriba from '../../Images/sapARIBA.png'
// const SAPCRMAribaContent = () => {
//     return (
//         <div className="container" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px' , paddingTop : '120px' , paddingBottom : '40px'}}>
//             <div className="text-center mb-4">
//                 <h3 className="text-success">SAP CRM/Ariba Solutions by LMV IT Solutions</h3>
//                 <p className="lead text-muted">
//                     Enhance customer relationship management and streamline procurement processes with SAP CRM and Ariba.
//                 </p>
//             </div>

//             <div className="row">
//                 {/* Image Section */}
//                 <div className="col-md-6 d-flex justify-content-center align-items-center">
//                     <img 
//                         src={SapAriba}
//                         alt="SAP CRM/Ariba" 
//                         className="img-fluid" 
//                     />
//                 </div>

//                 {/* Benefits Section */}
//                 <div className="col-md-6">
//                     <h4 className="mt-3 text-success">Why Choose SAP CRM/Ariba with LMV IT Solutions?</h4>
//                     <ul className="list-group list-group-flush">
//                         <li className="list-group-item bg-transparent">
//                             <strong>Enhanced Customer Relationships:</strong> Build stronger connections with customers through comprehensive CRM tools.
//                         </li>
//                         <li className="list-group-item bg-transparent">
//                             <strong>Streamlined Procurement:</strong> Simplify procurement processes with Ariba’s integrated digital platform.
//                         </li>
//                         <li className="list-group-item bg-transparent">
//                             <strong>Real-Time Collaboration:</strong> Facilitate smooth collaboration between suppliers and internal teams for efficient operations.
//                         </li>
//                         <li className="list-group-item bg-transparent">
//                             <strong>Increased Cost Savings:</strong> Optimize procurement and sourcing to maximize cost-efficiency.
//                         </li>
//                         <li className="list-group-item bg-transparent">
//                             <strong>Improved Analytics:</strong> Access detailed analytics for smarter, data-driven decisions in CRM and procurement.
//                         </li>
//                     </ul>
//                 </div>
//             </div>

//             <div className="text-center mt-5">
//                 <a href="#contact" className="btn btn-warning btn-lg shadow">
//                     Learn More about SAP CRM/Ariba
//                 </a>
//             </div>
//         </div>
//     );
// }

// export default SAPCRMAribaContent;
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SapAriba from '../../Images/sapAriiba.svg';
import Footer from '../Footer';

const SAPAribaContent = () => {
    return (
        <>
        <div className="container" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', paddingTop: '120px', paddingBottom: '40px' }}>
            <div className="text-center mb-4">
                <h3 className="text-success">SAP Ariba Solutions by LMV IT Solutions</h3>
                <p className="lead text-muted">
                    Streamline your procurement and supplier management processes with SAP Ariba, ensuring a more efficient and cost-effective supply chain.
                </p>
            </div>

            <div className="row">
                {/* Image Section */}
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <img 
                        src={SapAriba}
                        alt="SAP Ariba" 
                        className="img-fluid" 
                    />
                </div>

                {/* Benefits Section */}
                <div className="col-md-6">
                    <h4 className="mt-3 text-success">Why Choose SAP Ariba with LMV IT Solutions?</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item bg-transparent">
                            <strong>Streamlined Procurement Processes:</strong> Simplify and optimize your procurement activities with Ariba’s integrated digital platform.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Efficient Supplier Management:</strong> Manage and collaborate with suppliers effectively, enhancing transparency and communication.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Real-Time Insights:</strong> Access real-time data for better decision-making across the supply chain.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Cost Optimization:</strong> Leverage Ariba's tools to reduce procurement costs and improve cost-efficiency.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Compliance and Risk Management:</strong> Ensure compliance with industry standards and manage risks effectively in your procurement operations.
                        </li>
                    </ul>
                </div>
            </div>

           
        </div>
             <Footer />
             </>
    );
}

export default SAPAribaContent;
