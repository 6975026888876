import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { Button } from "../Button";
import { BiAlignRight } from "react-icons/bi";
import LMVLogo from "../../Images/PNG/logo.png";

const Nav = styled.nav`
  height: auto;
  display: flex;
  justify-content: space-between;

  padding: 10px 5px;
  z-index: 3;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
`;

const NavLink = css`
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  text-decoration: none;
`;

const Logo = styled.div`
  ${NavLink}
  width : 150px;
  height: 75px;
  padding: 0 20px;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 120px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const MenuBars = styled(BiAlignRight)`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    height: auto;
    width: 40px;
    color: #fff;
    background: #000;
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }
`;

const ServicesDiv = styled.div`
  position: relative;
  width: 100%;
`;

const SolutionsDiv = styled.div`
  position: relative;
  width: 100%;
`;

const SubMenuDiv = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  left: -5px;
  display: ${(props) => (props.open ? "initial" : "none")};
  animation: ${(props) => (props.open ? "open" : "close")} 0.3s ease;

  @keyframes open {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes close {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const SubMenuLinks = styled(Button)`
  width: 100%;
  height: 40px;
  padding: 5px;
  ${NavLink}
`;

const NavBtn = styled.div`
  display: flex;
  align-items: center;

  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Navbar = (props) => {
  const [openServices, setOpenServices] = useState(false);
  const [openSolutions, setOpenSolutions] = useState(false);

  let history = useHistory();

  const onClickLogoHandler = () => {
    history.push("/");
  };

  return (
    <Nav>
      <Logo onClick={onClickLogoHandler}>
        <img src={LMVLogo} />
      </Logo>
      <MenuBars onClick={props.toggle} />
      <NavBtn>
        <Button to="/" primary="true">
          Home
        </Button>
        <ServicesDiv
          onMouseOver={() => {
            setOpenServices(true);
            setOpenSolutions(false);
          }}
          onMouseOut={() => setOpenServices(false)}
        >
          <Button to="" primary="true">
            Services
          </Button>
          <SubMenuDiv open={openServices}>
            <SubMenuLinks to="sapservices" primary="true">
              SAP Services
            </SubMenuLinks>
            <SubMenuLinks to="uiuxdesigning" primary="true">
              UI UX Designing
            </SubMenuLinks>
            <SubMenuLinks to="appdevelopment" primary="true">
              App Development
            </SubMenuLinks>
            <SubMenuLinks to="webdevelopment" primary="true">
              Web Development
            </SubMenuLinks>
            <SubMenuLinks to="digitalmarketing" primary="true">
              Digital Marketing
            </SubMenuLinks>
          </SubMenuDiv>
        </ServicesDiv>
        <SolutionsDiv
          onMouseOver={() => {
            setOpenSolutions(true);
            setOpenServices(false);
          }}
          onMouseOut={() => setOpenSolutions(false)}
        >
          <Button to="" primary="true">
            Solutions
          </Button>
          <SubMenuDiv open={openSolutions}>
            {/* <SubMenuLinks to='SAPS4HanaContent' primary='true'>SAP S4 HANA</SubMenuLinks> */}
            <SubMenuLinks to="SAPCRMAribaContent" primary="true">
              SAP ARIBA
            </SubMenuLinks>
            <SubMenuLinks to="SAPWarehouseManagement" primary="true">
              SAP WM
            </SubMenuLinks>
            <SubMenuLinks to="SAPMaterialManagement" primary="true">
              SAP MM
            </SubMenuLinks>
            <SubMenuLinks to="SAPFinance" primary="true">
              SAP FICO
            </SubMenuLinks>
            <SubMenuLinks to="SAPSolutionsArchitect" primary="true">
              SAP BTP/CPI
            </SubMenuLinks>
            <SubMenuLinks to="SAPBasis" primary="true">
              SAP BASIS
            </SubMenuLinks>
            <SubMenuLinks to="SAPABAP" primary="true">
              SAP ABAP
            </SubMenuLinks>
            <SubMenuLinks to="SAPSD" primary="true">
              SAP SD
            </SubMenuLinks>
          </SubMenuDiv>
        </SolutionsDiv>
        <Button to="about" primary="true">
          About Us
        </Button>
        <Button to="contact" primary="true">
          Let's Talk
        </Button>
      </NavBtn>
    </Nav>
  );
};

export default Navbar;
