import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import sapfico from '../../Images/sapfi.png'
import Footer from '../Footer';
const SAPFinance = () => {
    return (
        <>
        <div className="container" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', paddingTop: '120px', paddingBottom: '40px' }}>
            <div className="text-center mb-4">
                <h3 className='text-success'>SAP Finance (FICO) Solutions by LMV IT Solutions</h3>
                <p className="lead text-muted">
                    Transform your financial operations with SAP Finance (FICO)—a comprehensive solution designed to streamline financial management and reporting.
                </p>
            </div>

            <div className="row">
                {/* Image Section */}
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <img 
                        src={sapfico}
                        alt="SAP Finance (FICO)" 
                        className="img-fluid" 
                    />
                </div>

                {/* Benefits Section */}
                <div className="col-md-6">
                    <h4 className="mt-3 text-success">Why Choose SAP Finance (FICO) with LMV IT Solutions?</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item bg-transparent">
                            <strong>Accurate Financial Reporting:</strong> Generate real-time financial statements and reports for better insights.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Streamlined Account Management:</strong> Simplify accounts payable and receivable processes for enhanced efficiency.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Integrated Financial Processes:</strong> Seamlessly integrate financial operations with other SAP modules for improved performance.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Regulatory Compliance:</strong> Ensure adherence to financial regulations and standards with built-in compliance features.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Advanced Analytics and Forecasting:</strong> Leverage data analytics for strategic planning and financial forecasting.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
             <Footer />
             </>
    );
}

export default SAPFinance;
