import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import sapBasis from '../../Images/SapBasis.svg'
import Footer from '../Footer';

const SAPBasis = () => {
   
    return (
        <>
        
        <div className="container" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', paddingTop: '120px', paddingBottom: '40px' }}>
            <div className="text-center mb-4">
                <h3 className='text-success'>SAP Basis Solutions by LMV IT Solutions</h3>
                <p className="lead text-muted">
                    Ensure the stability and performance of your SAP environment with our expert SAP Basis services, designed for seamless system administration and support.
                </p>
            </div>

            <div className="row">
                {/* Image Section */}
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <img 
                        src={sapBasis} 
                        alt="SAP Basis" 
                        className="img-fluid" 
                    />
                </div>

                {/* Benefits Section */}
                <div className="col-md-6">
                    <h4 className="mt-3 text-success">Why Choose SAP Basis with LMV IT Solutions?</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item bg-transparent">
                            <strong>System Performance Optimization:</strong> Monitor and enhance the performance of your SAP systems to ensure optimal operation.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Robust Security Measures:</strong> Implement comprehensive security protocols to safeguard your SAP environment against threats.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Efficient System Administration:</strong> Streamline administration tasks for better resource management and system maintenance.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Expert Troubleshooting:</strong> Leverage our expertise to quickly identify and resolve system issues, minimizing downtime.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Regular Updates and Upgrades:</strong> Ensure your SAP systems are up-to-date with the latest features and enhancements for continuous improvement.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
         <Footer />
       
        
         </>
    );
}

export default SAPBasis;




