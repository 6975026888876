import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import sapSD from '../../Images/sapsd.svg'
import Footer from '../Footer';
const SAPSD = () => {
    return (
        <>
        <div className="container" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', paddingTop: '120px', paddingBottom: '40px' }}>
            <div className="text-center mb-4">
                <h3 className='text-success'>SAP Sales and Distribution (SD) Solutions by LMV IT Solutions</h3>
                <p className="lead text-muted">
                    Optimize your sales processes and enhance customer satisfaction with our expert SAP SD solutions tailored to your business needs.
                </p>
            </div>

            <div className="row">
                {/* Image Section */}
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <img 
                        src={sapSD}
                        alt="SAP SD" 
                        className="img-fluid" 
                    />
                </div>

                {/* Benefits Section */}
                <div className="col-md-6">
                    <h4 className="mt-3 text-success">Why Choose SAP SD with LMV IT Solutions?</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item bg-transparent">
                            <strong>Streamlined Order Management:</strong> Automate and simplify the order-to-cash process to improve efficiency and accuracy.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Enhanced Customer Relationship Management:</strong> Gain insights into customer preferences and behaviors to strengthen relationships.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Comprehensive Sales Reporting:</strong> Access real-time data and analytics for informed decision-making and sales strategy adjustments.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Flexible Pricing and Discounting:</strong> Implement dynamic pricing strategies to adapt to market changes and customer needs.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Integration with Other SAP Modules:</strong> Seamlessly connect with Finance, Logistics, and other modules for cohesive operations.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
             <Footer />
             </>
    );
}

export default SAPSD;
