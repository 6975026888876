import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SapBpt from "../../Images/sapBpt.png";
import Footer from "../Footer";
const SAPSolutionsArchitect = () => {
  return (
    <>
    <div
      className="container"
      style={{
        backgroundColor: "#f8f9fa",
        borderRadius: "8px",
        paddingTop: "120px",
        paddingBottom: "40px",
      }}
    >
      <div className="text-center mb-4">
        <h3 className="text-success">
          SAP Solutions Architect (BTP/CPI) Solutions by LMV IT Solutions
        </h3>
        <p className="lead text-muted">
          Leverage the power of SAP Cloud Platform Integration (CPI) and
          Business Technology Platform (BTP) to drive innovation and efficiency
          in your organization.
        </p>
      </div>

      <div className="row">
        {/* Image Section */}
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <img
            src={SapBpt}
            alt="SAP Solutions Architect (BTP/CPI)"
            className="img-fluid"
          />
        </div>

        {/* Benefits Section */}
        <div className="col-md-6">
          <h4 className="mt-3 text-success">
            Why Choose SAP Solutions Architect (BTP/CPI) with LMV IT Solutions?
          </h4>
          <ul className="list-group list-group-flush">
            <li className="list-group-item bg-transparent">
              <strong>Seamless Integration:</strong> Connect applications and
              data across cloud and on-premise environments with ease.
            </li>
            <li className="list-group-item bg-transparent">
              <strong>Scalable Architecture:</strong> Design solutions that
              scale with your business needs, ensuring future growth and
              adaptability.
            </li>
            <li className="list-group-item bg-transparent">
              <strong>Enhanced Data Management:</strong> Utilize data-driven
              insights to improve decision-making and operational efficiency.
            </li>
            <li className="list-group-item bg-transparent">
              <strong>Innovation Enablement:</strong> Foster innovation through
              rapid development and deployment of new applications and services.
            </li>
            <li className="list-group-item bg-transparent">
              <strong>Expert Guidance:</strong> Benefit from the expertise of
              our certified SAP Solutions Architects to tailor solutions to your
              unique business requirements.
            </li>
          </ul>
        </div>
      </div>
    </div>
         <Footer />
         </>
  );
};

export default SAPSolutionsArchitect;
