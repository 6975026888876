import React, { Fragment, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import BgSAP from "../../Images/PNG/Web/BGSAP.jpg";
import styled from "styled-components";
import Bg1 from "../../Images/PNG/BG for SAP-02.png";
import SapServicesimage from "../../Images/PNG/Group 109.svg";
import { gsap } from "gsap/all";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Footer from "../Footer";


gsap.registerPlugin(ScrollTrigger);



  
  const AppSection = styled.section`
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
    background-image: url(${Bg1});
    background-position: center;
    object-fit: cover;
    padding: 0 10%;
    display: flex;
    justify-content: center;
    align-item: center;

    @media screen and (max-width: 768px) {
      object-fit: cover;
      flex-direction: column;
      padding: 0;
    }
  `;

  const BgImage1 = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -10;
  `;
  const SAPDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
    }
  `;

  const ImgDiv = styled.div`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;

svg{
    width : 100%;
    height : auto;
    filter: drop-shadow(0px 0px 10px #000);
    transform : scale(1.5)
}

    @media screen and (max-width: 768px) {
      display: initial;
    }
  `;
  const Heading = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  `;

  const HeadingDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    font-style: italic;
    justify-content: center;
    align-item: center;
    flex-direction: column;
    text-align: center;

    h1 {
      font-size: 2rem;
      letter-spacing: 3px;
    }

    h5 {
      text-align: center;
      margin-bottom: 15px;
    }

    p {
      font-size: 1rem;
      padding: 10px 0;
      font-weight: bold;
      line-height: 30px;
    }

    @media screen and (max-width: 768px) {
      h1 {
        font-size: 1.5rem;
        padding: 10px 0;
        letter-spacing: 5px;
      }

      p {
        font-size: 0.9rem;
        padding: 10px 10px;
      }
    }
  `;

  const Img = styled.img`
  width : 100%;
  height : auto;

    filter: drop-shadow(15px 15px 10px #000);
    transform : skew(-5deg) scale(0.9);

@media screen and (max-width : 768px ) {
  transform : skew(-5deg) scale(0.95);    
}
`;


  const SAPServices = () => {

    const [activeTab, setActiveTab] = useState("sapImplementation");
    useEffect(() =>{
      window.scrollTo(0, 0);
  }, [])

    const handleTabChange = (tabName) => {
      setActiveTab(tabName);
    };
  
  return (
    <Fragment>
      <AppSection className="section" id="section-1">
        <BgImage1 src={Bg1} />
        <Heading>
          <HeadingDiv>
            {/* <h1 data-aos="fade-right">SAP SERVICES</h1> */}
            <h1 >SAP SERVICES</h1>

            {/* <p data-aos="fade-right"> */}
            <p>
            "SAP services streamline operations, unify ERP, CRM, and analytics, and support real-time decision-making. Tailored for business needs, they optimize workflows, enhance customer relationships, and drive growth through data-driven solutions."
            </p>
          </HeadingDiv>
        </Heading>
        <SAPDiv>
          {/* <ImgDiv data-aos="zoom-in-left"> */}
          <ImgDiv>
            <Img src={SapServicesimage} />
          </ImgDiv>
        </SAPDiv>
      </AppSection>

  

      <div
        className="container d-flex flex-column align-items-center"
        style={{
          overflow: "auto",
          paddingTop: "130px",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        {/* Header section with blue background */}
        <div className="row w-100">
          <div
            className="col-12 text-center text-white py-2"
            style={{
              background:
                "linear-gradient(301deg, rgba(16,95,158,1) 0%, rgba(84,165,218,1) 100%)",
            }}
          >
            <h3>SAP SERVICES</h3>
          </div>
        </div>

        <div className="row justify-content-center mt-3">
          {/* Left side navigation (radio buttons for tab selection) */}
          <div className="col-md-3 col-12">
            <div
              className="list-group"
              style={{
                background:
                "linear-gradient(301deg, rgba(16,95,158,1) 0%, rgba(84,165,218,1) 100%)",
              }}
            >
              <h5 className="text-center text-white text-primary py-2">
                SERVICES
              </h5>
              <label
                className="list-group-item text-white fw-bold d-flex"
                style={{
                  background:
                       "linear-gradient(301deg, rgba(16,95,158,1) 0%, rgba(84,165,218,1) 100%)",
                }}
              >
                <input
                  type="radio"
                  className="form-check-input me-2"
                  checked={activeTab === "sapImplementation"}
                  onChange={() => handleTabChange("sapImplementation")}
                />
                SAP Implementation (S4 HANA)
              </label>
              <label
                className="list-group-item text-white fw-bold d-flex"
                style={{
                  background:
                       "linear-gradient(301deg, rgba(16,95,158,1) 0%, rgba(84,165,218,1) 100%)",
                }}
              >
                <input
                  type="radio"
                  className="form-check-input me-2"
                  checked={activeTab === "sapMaintenance"}
                  onChange={() => handleTabChange("sapMaintenance")}
                />
                SAP Maintenance & Support
              </label>
              <label0
                className="list-group-item text-white fw-bold d-flex"
                style={{
                  background:
                       "linear-gradient(301deg, rgba(16,95,158,1) 0%, rgba(84,165,218,1) 100%)",
                }}
              >
                <input
                  type="radio"
                  className="form-check-input me-2"
                  checked={activeTab === "sapUpgrade"}
                  onChange={() => handleTabChange("sapUpgrade")}
                />
                SAP Upgrade & Migration
              </label0>
              <label
                className="list-group-item text-white fw-bold"
                style={{
                  background:
                       "linear-gradient(301deg, rgba(16,95,158,1) 0%, rgba(84,165,218,1) 100%)",
                }}
              >
                <input
                  type="radio"
                  className="form-check-input me-2"
                  checked={activeTab === "sapTesting"}
                  onChange={() => handleTabChange("sapTesting")}
                />
                SAP Testing Service
              </label>
              {/* <label className="list-group-item">
              <input
                type="radio"
                className="form-check-input me-2"
                checked={activeTab === 'inventoryManagement'}
                onChange={() => handleTabChange('inventoryManagement')}
              />
              Inventory Management
            </label> */}
            </div>
          </div>

          {/* Right side content and image */}
          <div className="col-md-9 col-12">
            <div className="row">
              {/* Tab Content (text) */}
              <div className="col-md-12 col-12">
                <h3 className="mt-3">
                  {activeTab === "sapImplementation" &&
                    "SAP Implementation (S4 HANA)"}
                  {activeTab === "sapMaintenance" &&
                    "SAP Maintenance & Support"}
                  {activeTab === "sapUpgrade" && "SAP Upgrade & Migration"}
                  {activeTab === "sapTesting" && "SAP Testing Service"}
                  {/* {activeTab === 'inventoryManagement' && 'Inventory Management'} */}
                </h3>

                {/* Two paragraphs for each tab */}
                {activeTab === "sapImplementation" && (
                  <>
                    <p>
                      SAP implementation, or Systems, Applications, and Products
                      implementation is a process that outlines an enterprise
                      resource planning software implementation strategy in any
                      organization. This method is vital for an organization in
                      order to streamline the complex workflow structure and
                      reduces the efforts during the production phase. It is
                      also capable to integrate various organizational systems
                      and facilitates the transaction among them. A successful
                      implementation strategy can offer incredible benefits to
                      the organization as well as share an attractive array of
                      features with their customers.
                    </p>
                    <p>
                      LMV IT helps businesses achieve competitive advantage by
                      providing expert technology as well as domain experts for
                      configuring, implementing & supporting SAP solutions that
                      are aligned with industry best practices. The SAP Practice
                      at LMV IT has dedicated SAP consultants who have profound
                      knowledge of key subject areas based on experience and
                      practical hands-on knowledge across industry verticals.
                      LMV IT's SAP Practice with rich experience and stable
                      processes deliver solutions in the most optimal manner to
                      organizations of all sizes, ranging from small and midsize
                      businesses to global enterprises.
                    </p>
                  </>
                )}

                {activeTab === "sapMaintenance" && (
                  <>
                    <h5>Project Support</h5>
                    <p>
                      LMV IT provides essential project support, including
                      version updates, activation of applications, and
                      integration with new systems. We assist in creating
                      business blueprints, manage change requests, and implement
                      new modules to enhance your operations.
                    </p>

                    <h5>Business Support</h5>
                    <p>
                      Our business support services include a dedicated helpdesk
                      and user assistance to ensure smooth operations. We offer
                      troubleshooting, offshore development, and user training
                      to help your team maximize system efficiency.
                    </p>

                    <h5>Technical Support</h5>
                    <p>
                      LMV IT delivers comprehensive technical support, managing
                      hardware performance, application systems, and patch
                      fixes. We monitor user profiles, batch scheduling, and
                      interfaces, along with database and network management, to
                      maintain optimal system performance.
                    </p>
                  </>
                )}

                {activeTab === "sapUpgrade" && (
                  <>
                    <p>
                      With the introduction of new business and government laws,
                      market dynamics changed overnight. In addition, with the
                      introduction of new technologies, new and more powerful
                      versions of the SAP system have been continuously
                      developed and brought to the market. Therefore, it is
                      necessary to upgrade the existing system technology. Once
                      a newer major version is available, you don’t have to
                      worry about stagnant support for old versions and legacy
                      systems, as long as you understand the latest versions and
                      technologies of the products provided. SAP needs to be
                      updated regularly.
                    </p>

                    <h5>How can LMV IT help you?</h5>
                    <p>
                      Our migration and upgrade services are tailored to the
                      needs of the company when migrating to a new version or
                      new technology. Upgrading or migrating services is a
                      complex process that requires careful planning and
                      professional project management.
                    </p>

                    <h5>Importance of Upgrading SAP Systems</h5>
                    <p>
                      With new business laws and changing market dynamics, it’s
                      essential to keep your SAP system updated. New
                      technologies and versions are continually released,
                      ensuring you avoid issues with outdated support.
                    </p>

                    <h5>How LMV IT Can Help</h5>
                    <p>
                      LMV IT provides tailored migration and upgrade services to
                      fit your company’s needs. We understand that the upgrade
                      process is complex, requiring careful planning and project
                      management. Our team ensures a smooth transition to the
                      latest SAP technologies, helping you get the most out of
                      your investment.
                    </p>
                  </>
                )}

                {activeTab === "sapTesting" && (
                  <>
                    <h5>Testing Strategy and Assessment</h5>
                    <p>
                      LMV IT provides a robust testing strategy that includes
                      functional and regression testing to ensure system
                      integrity. We perform data migration testing for accuracy
                      and business process testing to verify operational
                      effectiveness. Integration testing checks system
                      interoperability, while user acceptance testing (UAT)
                      validates user requirements. We also implement test
                      automation for efficiency.
                    </p>

                    <h5>Performance and Security Testing</h5>
                    <p>
                      Our services include performance measurement and reporting
                      to analyze key metrics. We conduct security testing to
                      identify vulnerabilities and safeguard data.
                    </p>

                    <h5>Compliance and Tool Utilization</h5>
                    <p>
                      We ensure compliance with GRC Access Control and SAP
                      architecture standards through rigorous testing. Our
                      toolkit includes SAP LoadRunner, SAP Solution Manager,
                      QTP, and SAP eCATT for comprehensive testing solutions.
                    </p>
                  </>
                )}

                {/* {activeTab === 'inventoryManagement' && (
  <>
    <h5>Importance of Inventory Management</h5>
    <p>
      Inventory management is vital for business sustainability. Lack of control can lead to revenue loss. 
      Effective management enables precise planning for purchasing, storing, and selling commodities, components, 
      and finished goods.
    </p>

    <h5>How LMV IT Can Help</h5>
    <p>
      LMV IT specializes in optimizing inventory operations. Our experts enhance supply chain efficiency across 
      cycle stock, protection stock, pipeline, and pre-build inventory. We offer solutions for real-time inventory 
      tracking, cost analysis, and strategic planning, ultimately driving revenue growth for your organization.
    </p>
  </>
)} */}
              </div>

              {/* Tab Image */}
              {/* <div className="col-md-4 col-12">
              {activeTab === 'sapImplementation' && (
                <img
                  src="URL_TO_IMAGE_FOR_SAP_IMPLEMENTATION"
                  alt="SAP Implementation"
                  className="img-fluid mt-3"
                />
              )}
              {activeTab === 'sapMaintenance' && (
                <img
                  src="URL_TO_IMAGE_FOR_SAP_MAINTENANCE"
                  alt="SAP Maintenance"
                  className="img-fluid mt-3"
                />
              )}
              {activeTab === 'sapUpgrade' && (
                <img
                  src="URL_TO_IMAGE_FOR_SAP_UPGRADE"
                  alt="SAP Upgrade"
                  className="img-fluid mt-3"
                />
              )}
              {activeTab === 'sapTesting' && (
                <img
                  src="URL_TO_IMAGE_FOR_SAP_TESTING"
                  alt="SAP Testing"
                  className="img-fluid mt-3"
                />
              )}
              {activeTab === 'inventoryManagement' && (
                <img
                  src="URL_TO_IMAGE_FOR_INVENTORY_MANAGEMENT"
                  alt="Inventory Management"
                  className="img-fluid mt-3"
                />
              )}
            </div> */}
            </div>
          
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default SAPServices;
