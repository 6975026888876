import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import  Sapmm from '../../Images/SAP WEbsite Images-01.png'
import Footer from '../Footer';
const SAPMaterialManagement = () => {
    return (
        <>
        <div className="container" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', paddingTop: '120px', paddingBottom: '40px' }}>
            <div className="text-center mb-4">
                <h3 className='text-success'>SAP Material Management Solutions by LMV IT Solutions</h3>
                <p className="lead text-muted">
                    Enhance your supply chain efficiency with SAP Material Management—a robust solution designed to optimize procurement and inventory processes.
                </p>
            </div>

            <div className="row">
                {/* Image Section */}
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <img 
                        src={Sapmm}
                        alt="SAP Material Management" 
                        className="img-fluid " 
                    />
                </div>

                {/* Benefits Section */}
                <div className="col-md-6">
                    <h4 className="mt-3 text-success">Why Choose SAP Material Management with LMV IT Solutions?</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item bg-transparent">
                            <strong>Streamlined Procurement Processes:</strong> Simplify purchasing activities with automated workflows and vendor management.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Improved Inventory Control:</strong> Maintain optimal stock levels and minimize excess inventory to reduce carrying costs.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Enhanced Material Planning:</strong> Optimize material requirements planning for accurate forecasting and timely procurement.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Comprehensive Reporting and Analytics:</strong> Access real-time data insights for informed decision-making across the supply chain.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Integration with Other SAP Modules:</strong> Seamlessly connect with other SAP solutions to enhance overall business processes.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
             <Footer />
             </>
    );
}

export default SAPMaterialManagement;
