import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sapwm from '../../Images/sapWM.svg'
import Footer from '../Footer';
const SAPWarehouseManagement = () => {
    return (
        <>
        <div className="container" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px' , paddingTop : '120px' , paddingBottom : '40px'}}>
            <div className="text-center mb-4">
                <h3 className='text-success'>SAP Warehouse Management Solutions by LMV IT Solutions</h3>
                <p className="lead text-muted">
                    Optimize your warehouse operations with SAP Warehouse Management—a comprehensive solution designed to enhance efficiency and accuracy.
                </p>
            </div>

            <div className="row">
                {/* Image Section */}
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <img 
                        src={Sapwm} 
                        alt="SAP Warehouse Management" 
                        className="img-fluid " 
                    />
                </div>

                {/* Benefits Section */}
                <div className="col-md-6">
                    <h4 className="mt-3 text-success">Why Choose SAP Warehouse Management with LMV IT Solutions?</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item bg-transparent">
                            <strong>Increased Efficiency:</strong> Streamline warehouse operations with automated processes and real-time tracking.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Accurate Inventory Management:</strong> Gain complete visibility into inventory levels and locations to minimize errors.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Improved Space Utilization:</strong> Optimize storage layouts and reduce costs with better space management.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Enhanced Order Fulfillment:</strong> Accelerate order processing and improve customer satisfaction with faster deliveries.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Robust Reporting Tools:</strong> Access detailed analytics and reports for informed decision-making and strategic planning.
                        </li>
                    </ul>
                </div>
            </div>

           
        </div>
             <Footer />
             </>
    );
}

export default SAPWarehouseManagement;
