

// import React, { useState } from "react";
// import styled, { css } from "styled-components";
// import { Link, useRouteMatch } from "react-router-dom";
// import { ProSidebar, Menu, SideBarMenuItem, SubMenu } from "react-pro-sidebar";
// import "react-pro-sidebar/dist/css/styles.css";
// import { FaTimes } from "react-icons/fa";

// // const DropdownContainer = styled.div`
// //   position: fixed;
// //   z-index: 1000;
// //   width: 100%;
// //   height: 100vh;
// //   background: #000;
// //   display: flex;
// //   flex-direction:column;
// //   align-items: start;
// //   top: 0;
// //   left: 0;
// //   transition: 0.3s ease-in-out;
// //   opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
// //   top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
// //   padding-top: 10px;
// // `;
// // const DropdownWrapper = styled.div``;
// // const Icon = styled.div`
// //   position: absolute;
// //   top: 1.2rem;
// //   right: 1.2rem;
// //   // background: transparent;
// //   font-size: 2rem;
// //   cursor: pointer;
// //   outline: none;
// //   z-index: 1100; /* Ensure icon is above menu */
// // `;

// // const CloseIcon = styled(FaTimes)`
// //   color: #fff;
// //   z-index: 1100;
// //   background:#000;
// // `;

// // const HomeSideBar = styled(ProSidebar)`
// //   width: 100%;
// //   height:100%;
// //  background: #000;
// //   top: 2rem;
// // `;



// // const SideBarSideBarMenuItem = styled(SideBarMenuItem)`
// //   background: #000;
// //   width: 100%;
// //   height:100%;
// //   display: flex;
// //   flex-direction: column;
// //   align-items: start;
// //   color:white;
// // `;

// // // ///////////////////////////////
// // const SideBarMenu = styled(Menu)`
// //   background: '#000';
// //   display: flex;
// //   flex-direction: column;
// //   justify-content: space-around;
// //   align-items: center;
// // `;

// // const SideBarDiv = styled.div`
// //   width: 100%;
// //   height: auto;
// //   padding: 40px 0;
// // `;

// // const SideBarMenuItem = styled(MenuItem)`
// //   width: 100%;
// //   height: auto;
// //   text-align: center;
// //   background: '#000';
// // `;
// // const SideBarSubMenu = styled(SubMenu)`
// //   width: 100%;
// //   height: auto;
// //   text-align: center;
// //   background: '#000';
// // `;
// const DropdownContainer = styled.div`
//   position: fixed;
//   z-index: 100;
//   width: 100%;
//   height: 100vh;
//   background: #000;
//   display: grid;
//   align-items: center;
//   top: 0;
//   left: 0;
//   transition: 0.3s ease-in-out;
//   opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
//   top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
// `;

// const Icon = styled.div`
//   position: absolute;
//   top: 1.2rem;
//   right: 1.2rem;
//   background: transparent;
//   font-size: 2rem;
//   cursor: pointer;
//   outline: none;
// `;
// const CloseIcon = styled(FaTimes)`
//   color: #fff;
// `;

// const DropdownWrapper = styled.div``;

// const DropdownMenu = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: space-around;
// `;

// const BtnWrapper = styled.div`
//   display: flex;
//   justify-content: center;
// `;

// const DropdownLink = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin: 20px 0;
//   color: #fff;
//   font-size: 1.5rem;
//   text-decoration: none;
//   list-style: none;
//   cursor: pointer;
//   transition: 0.3s ease-in-out;

//   &:hover {
//     color: #000d1a;
//   }
// `;

// const HomeSideBar = styled(ProSidebar)``;

// const SideBarMenu = styled(Menu)`
//   background: '#000';
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
//   align-items: center;
// `;

// const SideBarDiv = styled.div`
//   width: 100%;
//   height: auto;
//   padding: 40px 0;
// `;

// const SideBarMenuItem = styled(MenuItem)`
//   width: 100%;
//   height: auto;
//   text-align: center;
//   background: '#000';
// `;
// const SideBarSubMenu = styled(SubMenu)`
//   width: 100%;
//   height: auto;
//   text-align: center;
//   background: '#000';
// `;
// const Dropdown = ({ isOpen, toggle }) => {
//   const { url } = useRouteMatch();
//   const [activeSubMenu, setActiveSubMenu] = useState("");

//   // const handleSubMenuToggle = (menuName) => {
//   //   setActiveSubMenu((prevMenu) => (prevMenu === menuName ? "" : menuName));
//   // };

//   return (
  
//       <DropdownContainer isOpen={isOpen}>
//       <Icon onClick={toggle}>
//         <CloseIcon />
//       </Icon>
//       <DropdownWrapper>
//       <HomeSideBar>
//         <SideBarMenu iconShape="square">
//           <SideBarMenuItem>
//             <Link onClick={toggle} to={`${url}`}>
//               Home
//             </Link>
//           </SideBarMenuItem>
//           <SideBarSubMenu
//             title="Services"
//             open={activeSubMenu === "Services"}
//             onOpenChange={() => handleSubMenuToggle("Services")}
//           >
//             <SideBarMenuItem>
//               <Link onClick={toggle} to="uiuxdesigning">
//                 UI/UX Designing
//               </Link>
//             </SideBarMenuItem>
//             <SideBarMenuItem>
//               <Link onClick={toggle} to="appdevelopment">
//                 App Development
//               </Link>
//             </SideBarMenuItem>
//             <SideBarMenuItem>
//               <Link onClick={toggle} to="webdevelopment">
//                 Web Development
//               </Link>
//             </SideBarMenuItem>
//             <SideBarMenuItem>
//               <Link onClick={toggle} to="digitalmarketing">
//                 Digital Marketing
//               </Link>
//             </SideBarMenuItem>
//             <SideBarMenuItem>
//               <Link onClick={toggle} to="sapservices">
//                 SAP SERVICES
//               </Link>
//             </SideBarMenuItem>
//           </SideBarSubMenu>
//           <SubMenu
//             title="Solutions"
//             open={activeSubMenu === "Solutions"}
//             onOpenChange={() => handleSubMenuToggle("Solutions")}
//           >
//             <SideBarMenuItem>
//               <Link onClick={toggle} to="SAPCRMAribaContent">
//                 SAP ARIBA
//               </Link>
//             </SideBarMenuItem>
//             <SideBarMenuItem>
//               <Link onClick={toggle} to="SAPWarehouseManagement">
//                 SAP WM
//               </Link>
//             </SideBarMenuItem>
//             <SideBarMenuItem>
//               <Link onClick={toggle} to="SAPMaterialManagement">
//                 SAP MM
//               </Link>
//             </SideBarMenuItem>
//             <SideBarMenuItem>
//               <Link onClick={toggle} to="SAPFinance">
//                 SAP FICO
//               </Link>
//             </SideBarMenuItem>
//             <SideBarMenuItem>
//               <Link onClick={toggle} to="SAPSolutionsArchitect">
//                 SAP BTP/CPI
//               </Link>
//             </SideBarMenuItem>
//             <SideBarMenuItem>
//               <Link onClick={toggle} to="SAPBasis">
//                 SAP BASIS
//               </Link>
//             </SideBarMenuItem>
//             <SideBarMenuItem>
//               <Link onClick={toggle} to="SAPABAP">
//                 SAP ABAP
//               </Link>
//             </SideBarMenuItem>
//             <SideBarMenuItem>
//               <Link onClick={toggle} to="SAPSD">
//                 SAP SD
//               </Link>
//             </SideBarMenuItem>
//           </SubMenu>
//           <SideBarMenuItem>
//             <Link onClick={toggle} to="contact">
//               Contact Us
//             </Link>
//           </SideBarMenuItem>
//           <SideBarMenuItem>
//             <Link onClick={toggle} to="about">
//               About Us
//             </Link>
//           </SideBarMenuItem>
//         </SideBarMenu>
//       </HomeSideBar>
//       </DropdownWrapper>
//     </DropdownContainer>
//   );
// };

// export default Dropdown;
import React, { useState } from "react";
import styled from "styled-components";
import { Link, useRouteMatch } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { FaTimes } from "react-icons/fa";

const DropdownContainer = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background: #1F1E1E;
  display: flex;

  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

const CloseIcon = styled(FaTimes)`
  color: #fff;
  width:100%;
  // height:100%;
  background:#000;
`;

const HomeSideBar = styled(ProSidebar)``;

const SideBarMenu = styled(Menu)`
  // background: '#fff';
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const SideBarMenuItem = styled(MenuItem)`
  width: 100%;
  height: auto;
  text-align: center;
  // background: '#fff';
`;

const SideBarSubMenu = styled(SubMenu)`
  width: 100%;
  height: auto;
  text-align: center;
  // background: '#fff';
`;

const Dropdown = ({ isOpen, toggle }) => {
  const { url } = useRouteMatch();
  const [activeSubMenu, setActiveSubMenu] = useState("");

  const handleSubMenuToggle = (menuName) => {
    setActiveSubMenu((prevMenu) => (prevMenu === menuName ? "" : menuName));
  };

  return (
    <DropdownContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <HomeSideBar>
        <SideBarMenu iconShape="square">
          <SideBarMenuItem>
            <Link onClick={toggle} to={`${url}`}>
              Home
            </Link>
          </SideBarMenuItem>
          <SideBarSubMenu
            title="Services"
            open={activeSubMenu === "Services"}
            onOpenChange={() => handleSubMenuToggle("Services")}
          >
            <SideBarMenuItem>
              <Link onClick={toggle} to="uiuxdesigning">
                UI/UX Designing
              </Link>
            </SideBarMenuItem>
            <SideBarMenuItem>
              <Link onClick={toggle} to="appdevelopment">
                App Development
              </Link>
            </SideBarMenuItem>
            <SideBarMenuItem>
              <Link onClick={toggle} to="webdevelopment">
                Web Development
              </Link>
            </SideBarMenuItem>
            <SideBarMenuItem>
              <Link onClick={toggle} to="digitalmarketing">
                Digital Marketing
              </Link>
            </SideBarMenuItem>
            <SideBarMenuItem>
              <Link onClick={toggle} to="sapservices">
                SAP Services
              </Link>
            </SideBarMenuItem>
          </SideBarSubMenu>
          <SideBarSubMenu
            title="Solutions"
            open={activeSubMenu === "Solutions"}
            onOpenChange={() => handleSubMenuToggle("Solutions")}
          >
            <SideBarMenuItem>
              <Link onClick={toggle} to="SAPCRMAribaContent">
                SAP Ariba
              </Link>
            </SideBarMenuItem>
            <SideBarMenuItem>
              <Link onClick={toggle} to="SAPWarehouseManagement">
                SAP WM
              </Link>
            </SideBarMenuItem>
            <SideBarMenuItem>
              <Link onClick={toggle} to="SAPMaterialManagement">
                SAP MM
              </Link>
            </SideBarMenuItem>
            <SideBarMenuItem>
              <Link onClick={toggle} to="SAPFinance">
                SAP FICO
              </Link>
            </SideBarMenuItem>
            <SideBarMenuItem>
              <Link onClick={toggle} to="SAPSolutionsArchitect">
                SAP BTP/CPI
              </Link>
            </SideBarMenuItem>
            <SideBarMenuItem>
              <Link onClick={toggle} to="SAPBasis">
                SAP Basis
              </Link>
            </SideBarMenuItem>
            <SideBarMenuItem>
              <Link onClick={toggle} to="SAPABAP">
                SAP ABAP
              </Link>
            </SideBarMenuItem>
            <SideBarMenuItem>
              <Link onClick={toggle} to="SAPSD">
                SAP SD
              </Link>
            </SideBarMenuItem>
          </SideBarSubMenu>
          <SideBarMenuItem>
            <Link onClick={toggle} to="contact">
              Contact Us
            </Link>
          </SideBarMenuItem>
          <SideBarMenuItem>
            <Link onClick={toggle} to="about">
              About Us
            </Link>
          </SideBarMenuItem>
        </SideBarMenu>
      </HomeSideBar>
    </DropdownContainer>
  );
};

export default Dropdown;
