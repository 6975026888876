import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import S4hana from '../../Images/Group 2 (1).png'
const SAPS4HanaContent = () => {
    return (
        <div className="container" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px' , paddingTop : '150px' , paddingBottom : '40px'}}>
            <div className="text-center mb-4">
                <h3 className='text-success'>SAP S4 HANA Solutions by LMV IT Solutions</h3>
                <p className="lead text-muted">Transforming businesses with real-time insights and enhanced operational efficiency.</p>
            </div>
            
            <div className="row">
                {/* Main Benefits Section */}
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <img 
                        src={S4hana} 
                        alt="SAP S4 HANA" 
                        className="" 
                        width='400px'
                        height='400px'
                    />
                </div>
                
                <div className="col-md-6">
                    <h4 className="mt-3 text-success">Why Choose SAP S4 HANA with LMV IT Solutions?</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item bg-transparent">
                            <strong>Real-Time Data Processing:</strong> Access and analyze data instantly, streamlining decision-making processes.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Increased Efficiency & Performance:</strong> Optimize workflows with SAP S4 HANA's in-memory computing capability.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Scalability & Flexibility:</strong> Adapts to growing business needs and integrates with cloud and on-premise systems.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Enhanced User Experience:</strong> Modern UI for easier adoption, minimizing training time.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Future-Ready Infrastructure:</strong> Supports AI, machine learning, and IoT integration.
                        </li>
                    </ul>
                </div>
            </div>
      
        </div>
    );
}

export default SAPS4HanaContent;
