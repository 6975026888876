import "./App.css";
import React, {  useState } from "react";
import Home from "./Components/Routes/HomePage";
import AboutUsPage from "./Components/Routes/AboutUsPage";
import ContactUsPage from "./Components/Routes/ContactUsPage";
import { useRouteMatch, Route, Redirect } from "react-router-dom";
import WebDevelopment from "./Components/Services/WebDevelopment";
import AppDevelopment from "./Components/Services/AppDevelopment";
import UI_UX from "./Components/Services/UI-UX";
import DigitalMarketing from "./Components/Services/DigitalMarketing";
import Navbar from "./Components/Nav/NavBar";
import Dropdown from "./Components/Nav/Dropdown";
import CRM from "./Components/Sliders Pages/CRM/CRM";
import DAR from "./Components/Sliders Pages/DAR/DAR";
import Payroll from "./Components/Sliders Pages/PAYROLL/Payroll";
import Samudra from "./Components/Sliders Pages/SAMUDHRA/Samudra";
import StudyTap from "./Components/Sliders Pages/STUDYTAP/StudyTap";
import UpButton from "./Components/Nav/UpButton";
import PrivacyPolicy from "./Components/Routes/PrivacyPolicy";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import SAPServices from "./Components/Services/SapServices";
import SAPS4HanaContent from "./Components/Solutions/S4Hana";

import SAPCRMAribaContent from "./Components/Solutions/SapCRMAriba";
import SAPWarehouseManagement from "./Components/Solutions/SapWM";
import SAPMaterialManagement from "./Components/Solutions/SapMM";
import SAPSolutionsArchitect from "./Components/Solutions/SapCPIBTP";
import SAPFinance from "./Components/Solutions/SapFICO";
import SAPBasis from "./Components/Solutions/SapBasis";
import SAPABAP from "./Components/Solutions/SapABAB";
import SAPSD from "./Components/Solutions/SapSD";
import ScrollToTop from "./Components/ScrollToTop";
// import Aos from "aos";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  console.log(isOpen);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const { path, url } = useRouteMatch();


  return (
    <div className="App">
      <Navbar toggle={toggle} />
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <UpButton />
      <ScrollToTop />
      <Route exact path={path}>
        <Home />
      </Route>
      <Route exact path={path + "about"}>
        <AboutUsPage />
      </Route>
      <Route exact path={path + "contact"}>
        <ContactUsPage />
      </Route>
      <Route exact path={path + "webdevelopment"}>
        <WebDevelopment />
      </Route>
      <Route exact path={path + "appdevelopment"}>
        <AppDevelopment />
      </Route>
      <Route exact path={path + "uiuxdesigning"}>
        <UI_UX />
      </Route>
      <Route exact path={path + "digitalmarketing"}>
        <DigitalMarketing />
      </Route>
      <Route exact path={path + "sapservices"}>
        <SAPServices />
      </Route>
      <Route exact path={path + "SAPS4HanaContent"}>
        <SAPS4HanaContent />
      </Route>

      <Route exact path={path + "SAPCRMAribaContent"}>
        <SAPCRMAribaContent />
      </Route>
      <Route exact path={path + "SAPWarehouseManagement"}>
        <SAPWarehouseManagement />
      </Route>
      <Route exact path={path + "SAPMaterialManagement"}>
        <SAPMaterialManagement />
      </Route>
      <Route exact path={path + "SAPFinance"}>
        <SAPFinance />
      </Route>
      <Route exact path={path + "SAPSolutionsArchitect"}>
        <SAPSolutionsArchitect />
      </Route>
      <Route exact path={path + "SAPBasis"}>
        <SAPBasis />
      </Route>
      <Route exact path={path + "SAPABAP"}>
        <SAPABAP />
      </Route>
      <Route exact path={path + "SAPSD"}>
        <SAPSD />
      </Route>

      <Route exact path={path + "crm"}>
        <CRM />
      </Route>
      <Route exact path={path + "dar"}>
        <DAR />
      </Route>
      <Route exact path={path + "payroll"}>
        <Payroll />
      </Route>
      <Route exact path={path + "samudra"}>
        <Samudra />
      </Route>
      <Route exact path={path + "studytap"}>
        <StudyTap />
      </Route>
      <Route exact path={path + "privacy-policy"}>
        <PrivacyPolicy />
      </Route>
      <Redirect from="*" to="/" />
    </div>
  );
}

export default App;
