// import React, { useEffect, useRef } from 'react';
// import { Images } from "../Data/ServicesData";
// import styled from 'styled-components';
// import { gsap } from "gsap/all";
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { Button } from "../Button";
// import { BsCheckAll } from "react-icons/bs";
// import { useRouteMatch } from "react-router-dom";
// gsap.registerPlugin(ScrollTrigger);

// const ServicesSection = styled.section`
//     width : 100%;
//     height : auto;
//     box-sizing : border-box;
//     padding : 20px 5%;
//     background : rgba(255, 255, 255);

// @media screen and (max-width: 768px) {
//     padding : 20px;
// }
// `

// const UiUxSection = styled.div`
//     width: 100%;
//     height: auto;
//     box-sizing : border-box;
//     overflow: hidden;
//     position : relative;
//     padding : 20px;

// @media screen and (max-width: 768px) {
//     height : auto;
//     padding : 0;
// }
// `;

// const AppSection = styled.div`
//     width: 100%;
//     height: auto;
//     box-sizing : border-box;
//     overflow: hidden;
//     position : relative;
//     padding : 20px;

// @media screen and (max-width: 768px) {
//     height : auto;
//     padding : 0;

// }
// `;

// const WebSection = styled.div`
//     width: 100%;
//     height: auto;
//     box-sizing : border-box;
//     overflow: hidden;
//     position : relative;
//     padding : 50px;

// @media screen and (max-width: 768px) {
//     height : auto;
//     padding : 0;
// }
// `;

// // background : linear-gradient( to left, rgba(0, 0, 0) 30%, rgba(150, 190, 225) 100%);

// const InnerSection = styled.div`
//     width : 100%;
//     height : auto;
//     display: flex;
//     justify-content : space-around;
//     box-sizing : border-box;
//     padding-top : 50px;

// @media screen and (max-width: 768px) {
//     flex-direction : ${props => props.primary};
// }
// `;

// const ContentSection = styled.div`
//     width : 100%;
//     height : auto;
//     display : flex;
//     align-items : center;
//     padding : 0 20px;
//     position : relative;

// @media screen and (max-width: 768px) {
//     padding : 20px 0;
// }
// `;

// const ImageSection = styled.div`
//     width : 100%;
//     height : auto;
//     display : flex;
//     justify-content : center;
//     align-item : center;
//     padding : 0 20px;

// @media screen and (max-width: 768px) {
//     padding : 20px 0;
// }
// `;

// const ImageDiv = styled.div`
//     width: 100%;
//     height : auto;
//     display : flex;
//     justify-content : center;
//     align-items : center;
//     position: relative;
// `;

// const BgImage = styled.img`
//     width: 80%;
//     position: absolute;
//     left: 50%;
//     opacity : 0.8;
//     -webkit-transform: translate(50%, 0) ;
//     -moz-transform: translate(50%, 0);
//     -o-transform: translate(50%, 0);
//     transform: translate(-50%, 0) ;
//     filter: drop-shadow( 4px 4px 4px rgba(0, 0, 0, .5));
//     z-index: 0;
// `;

// const Logo = styled.img`
//     width : ${props => props.primary};
//     filter: drop-shadow( 4px 4px 4px rgba(0, 0, 0, .5));
// `;

// const Heading = styled.h1`
//     text-align : center;
//     padding : 15px 0;
//     font-size : 2rem;
//     font-family : "Merriweather";
//     font-weight : bold;
//     letter-spacing : 5px;
//     margin-top : 30px;
// `;

// const ContentDiv = styled.div`
//     width : 100%;
//     height : auto;
//     position : relative;
//     color : white;
// `;

// const ContentBg = styled.div`
//     width : 100%;
//     height : auto;
//     position : absolute;
//     z-index : -20;
// `;

// const CheckIcon = styled(BsCheckAll)`
//     color : #fff;
//     width : 20px;
//     height : 20px;
//     margin-right : 5px;
//     margin-bottom : -5px;
// `;

// const InfoSection = styled.div`
//     width : 100%;
//     height : auto;
//     margin-top : 20px;
//     padding : 0 30px;

// @media screen and (max-width: 768px ) {
//     margin-top : 0;
//     padding : 0 10px;
// }
// `;

// const Info = styled.p`
//     padding : 10px 0;
//     font-family : "Merriweather";
//     line-height : 40px;

// @media screen and (max-width: 768px) {
//     line-height : 40px;
// }
// `;

// const Title = styled.h1`
//     text-align : center;
//     padding : 15px 0;
//     font-family : "Merriweather";
//     font-weight : bold;
//     letter-spacing : 5px;
// `;

// const Services = () => {

//     const ServiceSectionRef = useRef(null);

//     const UiUxSectionRef = useRef(null);
//     const UiUxBgImageRef = useRef(null);
//     const UiUxLogoRef = useRef(null);
//     const UiUxInfoRef = useRef(null);

//     const AppSectionRef = useRef(null);
//     const AppBgImageRef = useRef(null);
//     const AppLogoRef = useRef(null);
//     const AppInfoRef = useRef(null);

//     const WebSectionRef = useRef(null);
//     const WebBgImageRef = useRef(null);
//     const WebLogoRef = useRef(null);
//     const WebInfoRef = useRef(null);

//     const DMSectionRef = useRef(null);
//     const DMBgImageRef = useRef(null);
//     const DMLogoRef = useRef(null);
//     const DMInfoRef = useRef(null);

//     const AnimationObj = {
//         UiUx : {
//             section : UiUxSectionRef.current,
//             bg : UiUxBgImageRef.current,
//             logo : UiUxLogoRef.current,
//             info : UiUxInfoRef.current
//         },

//         App : {
//             section : AppSectionRef.current,
//             bg : AppBgImageRef.current,
//             logo : AppLogoRef.current,
//             info : AppInfoRef.current
//         },

//         Web : {
//             section : WebSectionRef.current,
//             bg : WebBgImageRef.current,
//             logo : WebLogoRef.current,
//             info : WebInfoRef.current
//         }

//     }

//     // const Animation = useCallback(() => {
//     //     const t1 = gsap.timeline();
//     //     t1.from(UiUxBgImageRef.current, { ease : 'ease-out', opacity : 0, duration : 1})
//     //         .from(UiUxLogoRef.current, { x : -500, scale : 0, ease : 'ease-out', opacity : 0, duration : 0.8}, '-=.5')
//     //         .from(UiUxInfoRef.current, { y : 50, ease : 'ease', opacity : 0, duration : 0.3}, '-=.8')

//     //     ScrollTrigger.create({
//     //         animation : t1,
//     //         trigger : UiUxSectionRef.current,
//     //         start : "top 80%",
//     //         toggleActions : "restart none none reset",
//     //     });

//     //     const t2 = gsap.timeline();
//     //     t2.from(AppBgImageRef.current, { ease : 'ease-out', opacity : 0, duration : 1})
//     //         .from(AppLogoRef.current, { x : 500, scale : 0, ease : 'ease-out', opacity : 0, duration : 0.8}, '-=.5')
//     //         .from(AppInfoRef.current, { y : 50, ease : 'ease', opacity : 0, duration : 0.3}, '-=.8')

//     //     ScrollTrigger.create({
//     //         animation : t2,
//     //         trigger : AppSectionRef.current,
//     //         start : "top 80%",
//     //         toggleActions : "restart none none reset",
//     //     });

//     //     const t3 = gsap.timeline();
//     //     t3.from(WebBgImageRef.current, { ease : 'ease-out', opacity : 0, duration : 1})
//     //         .from(WebLogoRef.current, { x : -500, scale : 0, ease : 'ease-out', opacity : 0, duration : 0.8}, '-=.5')
//     //         .from(WebInfoRef.current, { y : 50, ease : 'ease', opacity : 0, duration : 0.3}, '-=.8')

//     //     ScrollTrigger.create({
//     //         animation : t3,
//     //         trigger : WebSectionRef.current,
//     //         start : "top 80%",
//     //         toggleActions : "restart none none reset",
//     //     });

//     // }, [])

//     const { url, path } = useRouteMatch();

//     useEffect(() => {
//         const t1 = gsap.timeline();
//         t1.from(UiUxBgImageRef.current, { ease : 'ease-out', opacity : 0, duration : 1})
//             .from(UiUxLogoRef.current, { x : -500, scale : 0, ease : 'ease-out', opacity : 0, duration : 0.8}, '-=.5')
//             .from(UiUxInfoRef.current, { y : 50, ease : 'ease', opacity : 0, duration : 0.3}, '-=.8')
//             .fromTo(ServiceSectionRef.current,{backgroundColor : "white"}, {backgroundColor : '#77acf1', duration : 0.2, ease : 'ease-in-out'}, '-=.9')

//         ScrollTrigger.create({
//             animation : t1,
//             trigger : '#section-1',
//             start : "top 70%",
//             toggleActions : "restart none none reset",
//         });
//         const t2 = gsap.timeline();
//         t2.from(AppBgImageRef.current, { ease : 'ease-out', opacity : 0, duration : 1})
//             .from(AppLogoRef.current, { x : 500, scale : 0, ease : 'ease-out', opacity : 0, duration : 0.8}, '-=.5')
//             .from(AppInfoRef.current, { y : 50, ease : 'ease', opacity : 0, duration : 0.3}, '-=.8')
//             .to(ServiceSectionRef.current, {backgroundColor : '#5B5A77', duration : 0.2, ease : 'ease-in-out'}, '-=.9')

//         ScrollTrigger.create({
//             animation : t2,
//             trigger : '#section-2',
//             start : "top 70%",
//             toggleActions : "restart none none reset",
//         });

//         const t3 = gsap.timeline();
//         t3.from(WebBgImageRef.current, { ease : 'ease-out', opacity : 0, duration : 1})
//             .from(WebLogoRef.current, { x : -500, scale : 0, ease : 'ease-out', opacity : 0, duration : 0.8}, '-=.5')
//             .from(WebInfoRef.current, { y : 50, ease : 'ease', opacity : 0, duration : 0.3}, '-=.8')
//             .to(ServiceSectionRef.current, {backgroundColor : '#9087DD', duration : 0.2, ease : 'ease-in-out'}, '-=.9')

//         ScrollTrigger.create({
//             animation : t3,
//             trigger : '#section-3',
//             start : "top 70%",
//             toggleActions : "restart none none reset",
//         });

//         const t4 = gsap.timeline();
//         t4.from(DMBgImageRef.current, { ease : 'ease-out', opacity : 0, duration : 1})
//             .from(DMLogoRef.current, { x : 500, scale : 0, ease : 'ease-out', opacity : 0, duration : 0.8}, '-=.5')
//             .from(DMInfoRef.current, { y : 50, ease : 'ease', opacity : 0, duration : 0.3}, '-=.8')
//             .to(ServiceSectionRef.current, {backgroundColor : '#b83e6c', duration : 0.2, ease : 'ease-in-out'}, '-=.9')

//         ScrollTrigger.create({
//             animation : t4,
//             trigger : '#section-4',
//             start : "top 70%",
//             toggleActions : "restart none none reset",
//         });
//     }, [])

//     useEffect(() => {

//     }, [])

//     useEffect(() => {

//     }, [])

//         return (
//             <ServicesSection ref = {ServiceSectionRef}>
//             <Heading id = "services">Our Services</Heading>

//             <UiUxSection className="section" id='section-1' ref={UiUxSectionRef}>
//                 <InnerSection primary='column'>
//                     <ImageSection>
//                         <ImageDiv>
//                             <BgImage ref={UiUxBgImageRef} src={Images.Uiux.bg}/>
//                             <Logo primary="70%" ref={UiUxLogoRef} src={Images.Uiux.logo}/>
//                         </ImageDiv>
//                     </ImageSection>
//                     <ContentSection >
//                         <ContentDiv>
//                             <Title>UI / UX Designing</Title>
//                             <InfoSection ref={UiUxInfoRef}>
//                                 <Info ><CheckIcon/> User experience (UX) is the interaction and experience users have with a company's products and services. ... User interface (UI) is the specific asset users interact with. For example, UI can deal with traditional concepts like visual design elements such as colors and typography.</Info>
//                                 <Button to='uiuxdesigning' style={{margin : '20px auto'}} primary='true'>More details<i style={{padding: '0 10px'}} className="fas fa-arrow-right"></i></Button>
//                             </InfoSection>
//                         </ContentDiv>
//                     </ContentSection>
//                 </InnerSection>
//             </UiUxSection>

//             <AppSection className="section" id='section-2' ref={AppSectionRef}>
//                 <InnerSection primary='column-reverse'>
//                     <ContentSection >
//                         <ContentDiv>
//                             <Title>App Development</Title>
//                             <InfoSection ref={AppInfoRef}>
//                                 <Info><CheckIcon/>Apps are one of the best ways to boost engagement with your target audience, build strong brand loyalty, and ultimate cultivate a dedicated base of recurring customers/clients. Thinking about getting one built for your company? </Info>
//                                 <Button to='appdevelopment'  style={{margin : '20px auto'}} primary='true'>More details<i style={{padding: '0 10px'}} className="fas fa-arrow-right"></i></Button>
//                             </InfoSection>
//                         </ContentDiv>
//                     </ContentSection>
//                     <ImageSection >
//                         <ImageDiv>
//                             <BgImage ref={AppBgImageRef}  src={Images.App.bg}/>
//                             <Logo  primary="40%" ref={AppLogoRef} src={Images.App.logo}/>
//                         </ImageDiv>
//                     </ImageSection>
//                 </InnerSection>
//             </AppSection>

//             <WebSection className="section" id='section-3' ref={WebSectionRef}>
//                <InnerSection primary='column'>
//                     <ImageSection>
//                         <ImageDiv>
//                             <BgImage ref={WebBgImageRef} src={Images.Web.bg}/>
//                             <Logo style={{padding : '25px 0'}} primary="80%" ref={WebLogoRef} src={Images.Web.logo}/>
//                         </ImageDiv>
//                     </ImageSection>
//                     <ContentSection >
//                         <ContentDiv>
//                             <Title>Web Development</Title>
//                             <InfoSection ref={WebInfoRef}  onClick={() => console.log("Hello")}>
//                                 <Info><CheckIcon/> Websites are one of the best ways to boost engagement with your target audience, build strong brand loyalty, and ultimate cultivate a dedicated base of recurring customers/clients. Thinking about getting one built for your company? </Info>
//                                 <Button to='webdevelopment' onClick={() => console.log("Hello")} style={{margin : '20px auto'}} primary='true' color='red'>More details<i style={{padding: '0 10px'}} className="fas fa-arrow-right"></i></Button>
//                             </InfoSection>
//                         </ContentDiv>
//                     </ContentSection>
//                 </InnerSection>
//             </WebSection>

//             <AppSection className="section" id='section-4' ref={DMSectionRef}>
//                 <InnerSection primary='column-reverse'>
//                     <ContentSection >
//                         <ContentDiv>
//                             <Title>Digital Marketing</Title>
//                             <InfoSection ref={DMInfoRef}>
//                                 <Info><CheckIcon/>LMVIT Solutions , the Best Digital Marketing Company in Hyderabad is ready to Hit the Goal of your Business with our Sure-shot approach. May your company be a medium-scale business, a start-up in any stream, we will plan a perfect digital marketing strategy and help you gain better leads and sales and thus, increase your business ROI. Trust and let us handle all the digital marketing things of your business and we will make you achieve your business goals.</Info>
//                                 <Button to='digitalmarketing'  style={{margin : '20px auto'}} primary='true'>More details<i style={{padding: '0 10px'}} className="fas fa-arrow-right"></i></Button>
//                             </InfoSection>
//                         </ContentDiv>
//                     </ContentSection>
//                     <ImageSection >
//                         <ImageDiv>
//                             <BgImage ref={DMBgImageRef}  src={Images.App.bg}/>
//                             <Logo  primary="40%" ref={DMLogoRef} src={Images.Digital.logo}/>
//                         </ImageDiv>
//                     </ImageSection>
//                 </InnerSection>
//             </AppSection>
//             </ServicesSection>
//         )
// }

// export default Services;
import React, { useEffect, useRef } from "react";
import { Images } from "../Data/ServicesData";
import styled from "styled-components";
import { gsap } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Button } from "../Button";
import { BsCheckAll } from "react-icons/bs";
import { useRouteMatch } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);

const ServicesSection = styled.section`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 20px 5%;
  background: rgba(255, 255, 255);

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

const UiUxSection = styled.div`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  padding: 20px;

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 0;
  }
`;
const SAPSection = styled.div`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  padding: 20px;

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 0;
  }
`;

const AppSection = styled.div`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  padding: 20px;

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 0;
  }
`;

const WebSection = styled.div`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  padding: 50px;

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 0;
  }
`;

// background : linear-gradient( to left, rgba(0, 0, 0) 30%, rgba(150, 190, 225) 100%);

const InnerSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  padding-top: 50px;

  @media screen and (max-width: 768px) {
    flex-direction: ${(props) => props.primary};
  }
`;

const ContentSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 20px 0;
  }
`;

const ImageSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-item: center;
  padding: 0 20px;

  @media screen and (max-width: 768px) {
    padding: 20px 0;
  }
`;

const ImageDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const BgImage = styled.img`
  width: 80%;
  position: absolute;
  left: 50%;
  opacity: 0.8;
  -webkit-transform: translate(50%, 0);
  -moz-transform: translate(50%, 0);
  -o-transform: translate(50%, 0);
  transform: translate(-50%, 0);
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
  z-index: 0;
`;

const Logo = styled.img`
  width: ${(props) => props.primary};
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
`;

const Heading = styled.h1`
  text-align: center;
  padding: 15px 0;
  font-size: 2rem;
  font-family: "Merriweather";
  font-weight: bold;
  letter-spacing: 5px;
  margin-top: 30px;
`;

const ContentDiv = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  color: white;
`;

const ContentBg = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  z-index: -20;
`;

const CheckIcon = styled(BsCheckAll)`
  color: #fff;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-bottom: -5px;
`;

const InfoSection = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;
  padding: 0 30px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
    padding: 0 10px;
  }
`;

const Info = styled.p`
  padding: 10px 0;
  font-family: "Merriweather";
  line-height: 40px;

  @media screen and (max-width: 768px) {
    line-height: 40px;
  }
`;

const Title = styled.h1`
  text-align: center;
  padding: 15px 0;
  font-family: "Merriweather";
  font-weight: bold;
  letter-spacing: 5px;
`;

const Services = () => {
  const ServiceSectionRef = useRef(null);

  const UiUxSectionRef = useRef(null);
  const UiUxBgImageRef = useRef(null);
  const UiUxLogoRef = useRef(null);
  const UiUxInfoRef = useRef(null);

  const SAPSectionRef = useRef(null);
  const SAPBgImageRef = useRef(null);
  const SAPLogoRef = useRef(null);
  const SAPInfoRef = useRef(null);

  const AppSectionRef = useRef(null);
  const AppBgImageRef = useRef(null);
  const AppLogoRef = useRef(null);
  const AppInfoRef = useRef(null);

  const WebSectionRef = useRef(null);
  const WebBgImageRef = useRef(null);
  const WebLogoRef = useRef(null);
  const WebInfoRef = useRef(null);

  const DMSectionRef = useRef(null);
  const DMBgImageRef = useRef(null);
  const DMLogoRef = useRef(null);
  const DMInfoRef = useRef(null);

  const AnimationObj = {
    UiUx: {
      section: UiUxSectionRef.current,
      bg: UiUxBgImageRef.current,
      logo: UiUxLogoRef.current,
      info: UiUxInfoRef.current,
    },

    App: {
      section: AppSectionRef.current,
      bg: AppBgImageRef.current,
      logo: AppLogoRef.current,
      info: AppInfoRef.current,
    },

    Web: {
      section: WebSectionRef.current,
      bg: WebBgImageRef.current,
      logo: WebLogoRef.current,
      info: WebInfoRef.current,
    },
    SAP: {
      section: SAPSectionRef.current,
      bg: SAPBgImageRef.current,
      logo: SAPLogoRef.current,
      info: SAPInfoRef.current,
    },
  };

  // const Animation = useCallback(() => {
  //     const t1 = gsap.timeline();
  //     t1.from(UiUxBgImageRef.current, { ease : 'ease-out', opacity : 0, duration : 1})
  //         .from(UiUxLogoRef.current, { x : -500, scale : 0, ease : 'ease-out', opacity : 0, duration : 0.8}, '-=.5')
  //         .from(UiUxInfoRef.current, { y : 50, ease : 'ease', opacity : 0, duration : 0.3}, '-=.8')

  //     ScrollTrigger.create({
  //         animation : t1,
  //         trigger : UiUxSectionRef.current,
  //         start : "top 80%",
  //         toggleActions : "restart none none reset",
  //     });

  //     const t2 = gsap.timeline();
  //     t2.from(AppBgImageRef.current, { ease : 'ease-out', opacity : 0, duration : 1})
  //         .from(AppLogoRef.current, { x : 500, scale : 0, ease : 'ease-out', opacity : 0, duration : 0.8}, '-=.5')
  //         .from(AppInfoRef.current, { y : 50, ease : 'ease', opacity : 0, duration : 0.3}, '-=.8')

  //     ScrollTrigger.create({
  //         animation : t2,
  //         trigger : AppSectionRef.current,
  //         start : "top 80%",
  //         toggleActions : "restart none none reset",
  //     });

  //     const t3 = gsap.timeline();
  //     t3.from(WebBgImageRef.current, { ease : 'ease-out', opacity : 0, duration : 1})
  //         .from(WebLogoRef.current, { x : -500, scale : 0, ease : 'ease-out', opacity : 0, duration : 0.8}, '-=.5')
  //         .from(WebInfoRef.current, { y : 50, ease : 'ease', opacity : 0, duration : 0.3}, '-=.8')

  //     ScrollTrigger.create({
  //         animation : t3,
  //         trigger : WebSectionRef.current,
  //         start : "top 80%",
  //         toggleActions : "restart none none reset",
  //     });

  // }, [])

  const { url, path } = useRouteMatch();

  useEffect(() => {
    const t1 = gsap.timeline();
    t1.from(UiUxBgImageRef.current, {
      ease: "ease-out",
      opacity: 0,
      duration: 1,
    })
      .from(
        UiUxLogoRef.current,
        { x: -500, scale: 0, ease: "ease-out", opacity: 0, duration: 0.8 },
        "-=.5"
      )
      .from(
        UiUxInfoRef.current,
        { y: 50, ease: "ease", opacity: 0, duration: 0.3 },
        "-=.8"
      )
      .fromTo(
        ServiceSectionRef.current,
        { backgroundColor: "white" },
        { backgroundColor: "#77acf1", duration: 0.2, ease: "ease-in-out" },
        "-=.9"
      );

    ScrollTrigger.create({
      animation: t1,
      trigger: "#section-1",
      start: "top 70%",
      toggleActions: "restart none none reset",
    });
    const t2 = gsap.timeline();
    t2.from(AppBgImageRef.current, {
      ease: "ease-out",
      opacity: 0,
      duration: 1,
    })
      .from(
        AppLogoRef.current,
        { x: 500, scale: 0, ease: "ease-out", opacity: 0, duration: 0.8 },
        "-=.5"
      )
      .from(
        AppInfoRef.current,
        { y: 50, ease: "ease", opacity: 0, duration: 0.3 },
        "-=.8"
      )
      .to(
        ServiceSectionRef.current,
        { backgroundColor: "#5B5A77", duration: 0.2, ease: "ease-in-out" },
        "-=.9"
      );

    ScrollTrigger.create({
      animation: t2,
      trigger: "#section-2",
      start: "top 70%",
      toggleActions: "restart none none reset",
    });

    const t3 = gsap.timeline();
    t3.from(WebBgImageRef.current, {
      ease: "ease-out",
      opacity: 0,
      duration: 1,
    })
      .from(
        WebLogoRef.current,
        { x: -500, scale: 0, ease: "ease-out", opacity: 0, duration: 0.8 },
        "-=.5"
      )
      .from(
        WebInfoRef.current,
        { y: 50, ease: "ease", opacity: 0, duration: 0.3 },
        "-=.8"
      )
      .to(
        ServiceSectionRef.current,
        { backgroundColor: "#9087DD", duration: 0.2, ease: "ease-in-out" },
        "-=.9"
      );

    ScrollTrigger.create({
      animation: t3,
      trigger: "#section-3",
      start: "top 70%",
      toggleActions: "restart none none reset",
    });

    const t4 = gsap.timeline();
    t4.from(DMBgImageRef.current, { ease: "ease-out", opacity: 0, duration: 1 })
      .from(
        DMLogoRef.current,
        { x: 500, scale: 0, ease: "ease-out", opacity: 0, duration: 0.8 },
        "-=.5"
      )
      .from(
        DMInfoRef.current,
        { y: 50, ease: "ease", opacity: 0, duration: 0.3 },
        "-=.8"
      )
      .to(
        ServiceSectionRef.current,
        { backgroundColor: "#b83e6c", duration: 0.2, ease: "ease-in-out" },
        "-=.9"
      );

    ScrollTrigger.create({
      animation: t4,
      trigger: "#section-4",
      start: "top 70%",
      toggleActions: "restart none none reset",
    });
    const t5 = gsap.timeline();
    t5.from(SAPBgImageRef.current, {
      ease: "ease-out",
      opacity: 0,
      duration: 1,
    })
      .from(
        SAPLogoRef.current,
        { x: -500, scale: 0, ease: "ease-out", opacity: 0, duration: 0.8 },
        "-=.5"
      )
      .from(
        SAPInfoRef.current,
        { y: 50, ease: "ease", opacity: 0, duration: 0.3 },
        "-=.8"
      )
      .fromTo(
        ServiceSectionRef.current,
        { backgroundColor: "#b83e6c" },
        { backgroundColor: "#54A4DA", duration: 0.2, ease: "ease-in-out" },
        "-=.9"
      );
    // 54A4DA

    ScrollTrigger.create({
      animation: t5,
      trigger: "#section-5",
      start: "top 70%",
      toggleActions: "restart none none reset",
    });
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {}, []);

  return (
    <ServicesSection ref={ServiceSectionRef}>
      <Heading id="services">Our Services</Heading>

      <UiUxSection className="section" id="section-1" ref={UiUxSectionRef}>
        <InnerSection primary="column">
          <ImageSection>
            <ImageDiv>
              <BgImage ref={UiUxBgImageRef} src={Images.Uiux.bg} />
              <Logo primary="70%" ref={UiUxLogoRef} src={Images.Uiux.logo} />
            </ImageDiv>
          </ImageSection>
          <ContentSection>
            <ContentDiv>
              <Title>UI / UX Designing</Title>
              <InfoSection ref={UiUxInfoRef}>
                <Info>
                  <CheckIcon /> User experience (UX) is the interaction and
                  experience users have with a company's products and services.
                  ... User interface (UI) is the specific asset users interact
                  with. For example, UI can deal with traditional concepts like
                  visual design elements such as colors and typography.
                </Info>
                <Button
                  to="uiuxdesigning"
                  style={{ margin: "20px auto" }}
                  primary="true"
                >
                  More details
                  <i
                    style={{ padding: "0 10px" }}
                    className="fas fa-arrow-right"
                  ></i>
                </Button>
              </InfoSection>
            </ContentDiv>
          </ContentSection>
        </InnerSection>
      </UiUxSection>

      <AppSection className="section" id="section-2" ref={AppSectionRef}>
        <InnerSection primary="column-reverse">
          <ContentSection>
            <ContentDiv>
              <Title>App Development</Title>
              <InfoSection ref={AppInfoRef}>
                <Info>
                  <CheckIcon />
                  Apps are one of the best ways to boost engagement with your
                  target audience, build strong brand loyalty, and ultimate
                  cultivate a dedicated base of recurring customers/clients.
                  Thinking about getting one built for your company?{" "}
                </Info>
                <Button
                  to="appdevelopment"
                  style={{ margin: "20px auto" }}
                  primary="true"
                >
                  More details
                  <i
                    style={{ padding: "0 10px" }}
                    className="fas fa-arrow-right"
                  ></i>
                </Button>
              </InfoSection>
            </ContentDiv>
          </ContentSection>
          <ImageSection>
            <ImageDiv>
              <BgImage ref={AppBgImageRef} src={Images.App.bg} />
              <Logo primary="40%" ref={AppLogoRef} src={Images.App.logo} />
            </ImageDiv>
          </ImageSection>
        </InnerSection>
      </AppSection>

      <WebSection className="section" id="section-3" ref={WebSectionRef}>
        <InnerSection primary="column">
          <ImageSection>
            <ImageDiv>
              <BgImage ref={WebBgImageRef} src={Images.Web.bg} />
              <Logo
                style={{ padding: "25px 0" }}
                primary="80%"
                ref={WebLogoRef}
                src={Images.Web.logo}
              />
            </ImageDiv>
          </ImageSection>
          <ContentSection>
            <ContentDiv>
              <Title>Web Development</Title>
              <InfoSection
                ref={WebInfoRef}
                onClick={() => console.log("Hello")}
              >
                <Info>
                  <CheckIcon /> Websites are one of the best ways to boost
                  engagement with your target audience, build strong brand
                  loyalty, and ultimate cultivate a dedicated base of recurring
                  customers/clients. Thinking about getting one built for your
                  company?{" "}
                </Info>
                <Button
                  to="webdevelopment"
                  onClick={() => console.log("Hello")}
                  style={{ margin: "20px auto" }}
                  primary="true"
                  color="red"
                >
                  More details
                  <i
                    style={{ padding: "0 10px" }}
                    className="fas fa-arrow-right"
                  ></i>
                </Button>
              </InfoSection>
            </ContentDiv>
          </ContentSection>
        </InnerSection>
      </WebSection>

      <AppSection className="section" id="section-4" ref={DMSectionRef}>
        <InnerSection primary="column-reverse">
          <ContentSection>
            <ContentDiv>
              <Title>Digital Marketing</Title>
              <InfoSection ref={DMInfoRef}>
                <Info>
                  <CheckIcon />
                  LMVIT Solutions , the Best Digital Marketing Company in
                  Hyderabad is ready to Hit the Goal of your Business with our
                  Sure-shot approach. May your company be a medium-scale
                  business, a start-up in any stream, we will plan a perfect
                  digital marketing strategy and help you gain better leads and
                  sales and thus, increase your business ROI. Trust and let us
                  handle all the digital marketing things of your business and
                  we will make you achieve your business goals.
                </Info>
                <Button
                  to="digitalmarketing"
                  style={{ margin: "20px auto" }}
                  primary="true"
                >
                  More details
                  <i
                    style={{ padding: "0 10px" }}
                    className="fas fa-arrow-right"
                  ></i>
                </Button>
              </InfoSection>
            </ContentDiv>
          </ContentSection>
          <ImageSection>
            <ImageDiv>
              <BgImage ref={DMBgImageRef} src={Images.App.bg} />
              <Logo primary="60%" ref={DMLogoRef} src={Images.Digital.logo} />
            </ImageDiv>
          </ImageSection>
        </InnerSection>
      </AppSection>

      <SAPSection className="section" id="section-5" ref={SAPSectionRef}>
        <InnerSection primary="column">
          <ImageSection>
            <ImageDiv>
              <BgImage ref={SAPBgImageRef} src={Images.SAP.bg} />
              <Logo primary="60%" ref={SAPLogoRef} src={Images.SAP.logo} />
            </ImageDiv>
          </ImageSection>
          <ContentSection>
            <ContentDiv>
              <Title>SAP Services</Title>
              <InfoSection ref={SAPInfoRef}>
                <Info>
                  <CheckIcon />
                  SAP services encompass a suite of integrated applications and
                  tools that streamline business operations, manage data, and
                  enhance decision-making. From SAP S/4HANA for real-time data
                  processing to SuccessFactors for human capital management, SAP
                  solutions unify core functions like ERP, CRM, procurement, and
                  analytics within a cohesive system. Each service is tailored
                  to specific business needs, enabling companies to optimize
                  workflows, improve customer relationships, and drive growth
                  through seamless, data-driven processes.
                </Info>
                <Button
                  to="sapservices"
                  style={{ margin: "20px auto" }}
                  primary="true"
                >
                  More details
                  <i
                    style={{ padding: "0 10px" }}
                    className="fas fa-arrow-right"
                  ></i>
                </Button>
              </InfoSection>
            </ContentDiv>
          </ContentSection>
        </InnerSection>
      </SAPSection>
    </ServicesSection>
  );
};

export default Services;
