import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import sapABAP from '../../Images/sapabap.png'
import Footer from '../Footer';
const SAPABAP = () => {
    return (
        <>
        <div className="container" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', paddingTop: '120px', paddingBottom: '40px' }}>
            <div className="text-center mb-4">
                <h3 className='text-success'>SAP ABAP Development Solutions by LMV IT Solutions</h3>
                <p className="lead text-muted">
                    Maximize your SAP system's capabilities with our specialized ABAP development services, crafted to enhance your business processes.
                </p>
            </div>

            <div className="row">
                {/* Image Section */}
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <img 
                        src={sapABAP}
                        alt="SAP ABAP" 
                        className="img-fluid  " 
                    />
                </div>

                {/* Benefits Section */}
                <div className="col-md-6">
                    <h4 className="mt-3 text-success">Benefits of SAP ABAP Development with LMV IT Solutions</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item bg-transparent">
                            <strong>Custom Application Development:</strong> Create tailored applications that meet your organization's specific needs and requirements.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Efficient Report Generation:</strong> Develop custom reports that provide critical business insights and enhance decision-making.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Seamless Integration:</strong> Integrate ABAP solutions with existing SAP and third-party systems for a cohesive operational environment.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Performance Optimization:</strong> Leverage best coding practices to ensure high performance and reliability of your applications.
                        </li>
                        <li className="list-group-item bg-transparent">
                            <strong>Ongoing Support and Maintenance:</strong> Access our dedicated support team for assistance and maintenance of your ABAP solutions.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
}

export default SAPABAP;
